
import { Component, Vue } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'
import Configuration from '@/store/modules/configuration'
import Translations from '@/store/modules/translations'

const translations = getModule(Translations) // Store accessor.
const configuration = getModule(Configuration)

const TITLE_KEY = 'errorBrowserUnsupportedTitle'

@Component
export default class Header extends Vue {
  isBrowserSupported = this.$browserDetect.isChrome || this.$browserDetect.isSafari || this.$browserDetect.isEdge || this.$browserDetect.isFirefox
  contentText = translations.getTranslations(TITLE_KEY)

  get region () {
    return configuration.region
  }

  get version () {
    return configuration.version
  }
}
