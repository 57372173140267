export const TRANSLATIONS: TranslationsConfigProps = {
  headerWebsiteTitle: 'Voice Collection Service',
  progressListWelcome: 'Welcome',
  progressListYourDetails: 'Dettagli utente',
  progressListBackgroundAudioCheck: 'Controllo audio in background',
  progressListRecordings: 'Recordings',
  progressListDone: 'Fatto',
  homeHelloText: 'Ciao',
  homeNeedHelpText: 'Abbiamo bisogno del tuo aiuto',
  homeThankYouText: 'A Sky vogliamo rendere il nostro controllo vocale ancora migliore". Per questo motivo stiamo raccogliendo campioni vocali da tutta la Germania, in modo che in futuro possiate capire ancora meglio il nostro controllo vocale',
  homeSamplesText: 'Abbiamo bisogno di un numero ancora maggiore di campioni, soprattutto da parte dei bambini sotto i 10 anni. Quindi se avete voglia di partecipare, non è troppo tardi e ci sono 10 buoni cinema Sky Store in palio. Il termine ultimo per partecipare è il 18 dicembre',
  homeSpareTimeText: 'Ha 5-10 minuti per noi? Leggere le informazioni legali riportate nel link sottostante e fare clic su "Continua" se si accetta',
  homeNextText: 'Continua',
  homeCloseText: 'Chiudi',
  userDetailsTitleText: 'I tuoi dati',
  userDetailsSessionText: [
    'Questa sessione è solo per',
    'uno',
    'persona. È possibile aggiungere altre persone dopo la sessione'
  ],
  userDetailsFormLabelsText: [
    'gruppo di età *',
    'Genere *',
    'Il vostro dialetto (migliore approssimazione possibile) *',
    'La vostra regione (migliore approssimazione possibile) *',
    'Lingua madre *',
    'Seconda lingua madre'
  ],
  userDetailsNextText: 'Next',
  backgroundAudioCheckTitleText: 'Controllo audio di fondo',
  backgroundAudioCheckPermissionForMicText: 'Abbiamo bisogno del vostro OK per controllare l\'audio di sottofondo attraverso il vostro microfono',
  backgroundAudioCheckFindQuietSpaceText: 'Trova un posto il più tranquillo possibile per la tua registrazione',
  backgroundAudioCheckHeadsetText: 'Se si dispone di cuffie o auricolari, utilizzarli e assicurarsi che siano impostati come microfono per il browser',
  backgroundAudioCheckCheckText: 'Controlla il rumore di fondo',
  backgroundAudioCheckAudioGoodText: [
    'Il vostro rumore di fondo è sufficientemente basso!',
    'Siete pronti a registrare'
  ],
  backgroundAudioCheckBeginRecordingText: 'Inizio registrazione',
  backgroundAudioCheckErrorText: 'Il rumore di fondo è un po\' troppo forte. Per favore, trovate un posto più tranquillo',
  recordingsTitleText: 'Registrazione',
  recordingsGetReadyText: 'Preparati...',
  recordingsSayWakewordText: 'Di\': ',
  recordingsBeginText: 'Start',
  recordingsHaveAListenText: 'Riascolta le tue registrazioni qui',
  recordingsRedoRecordingText: 'Registra di nuovo',
  recordingsNextRecordingText: 'Prossima registrazione',
  recordingsSpeechInstructionsText: 'Pronuncia il testo esattamente una volta. Poi aspettate che il timer finisca',
  recordingsStayQuietText: 'Si prega di rimanere il più possibile in silenzio durante il conto alla rovescia!',
  recordingsAreYouStillThereText: 'Ciao... sei ancora lì?',
  recordingsGoneQuietText: 'È diventato così silenzioso...',
  recordingsRefreshPromptText: 'Fare clic sul pulsante sottostante per aggiornare la pagina',
  recordingsRefreshText: 'Ricarica la pagina',
  recordingsReturnHelloText: 'Hello',
  recordingsReturnText: 'Non è stata completata l\'ultima sessione. Vuole continuare qui?',
  recordingsReturnCarryOnText: 'Sì, continua la sessione',
  recordingsReturnRestartText: 'No, ricomincia dall\'inizio',
  errorBrowserUnsupportedTitle: 'Il browser non è supportato',
  errorBrowserUnsupportedText: 'Al momento sono supportati solo Chrome, Firefox, Safari... ed Edge',
  doneThankYouText: 'Grazie',
  doneAllDoneText: 'Fatto!',
  doneContactText: 'Per aggiungere altri membri della famiglia, cliccare su "Aggiungi altre persone"',
  doneAddMoreText: 'Aggiungi altre persone',
  toastErrorErrorTitle: 'Errore',
  toastErrorRecordingsTitle: 'Registrazioni',
  toastErrorUserCreate: 'L\'utente non può essere registrato',
  toastErrorBackgroundNoise: 'Non è stato possibile verificare il rumore di fondo',
  toastErrorMicrophoneAccess: 'Nessun accesso al microfono. Aggiornare l\'autorizzazione e riprovare',
  toastErrorRecordPartCreate: 'Non è stato possibile inviare la registrazione',
  toastErrorTokenUnauthorised: 'Non è stato possibile concedere l\'accesso a questa funzione. Riprovare, aggiornare la pagina o accedere nuovamente',
  homeContinueText: 'Continua',
  homeTermsAndConditions: 'Termini e condizioni',
  homeRequiredCheckboxes: 'Le caselle contrassegnate da un asterisco devono essere compilate!',
  recordingsContinueSessionText: 'Continua la sessione',
  recordingsSatisfiedText: 'Sei soddisfatto del risultato?',
  recordingsFinishText: 'Fine sessione',
  recordingsProgressText: 'I tuoi progressi:',
  contactEmail: 'email@addr.de'
}
